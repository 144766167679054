import React from "react";

import UniversalAppBar from "../components/UniversalAppBar";
import { Container, Avatar, Box, Button, TextField, IconButton, Grid, Typography } from '@mui/material';
import Main from "../containers/Root/MainContainer";

export default function MainViews() {
  return (
    <React.Fragment>
      <Container maxWidth="md" >
        <Main />
      </Container>
    </React.Fragment>
  );
}