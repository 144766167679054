import React, { Fragment } from "react";
import { Container } from '@mui/material';

import DogFoodCalculator from "./MainBlocks";

export default function MainContainer() {
  return (
    <Fragment>
      <Container maxWidth="sm">
        <DogFoodCalculator />
      </Container>
    </Fragment>
  );
}
