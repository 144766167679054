import React from 'react';
import ReactDOM from 'react-dom/client';

import { RecoilRoot } from "recoil";
import createRoutes from "./routes";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "./configs/customTheme";
import './index.css';

import reportWebVitals from './reportWebVitals';


const routes = createRoutes();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {routes}
      </ThemeProvider>
    </RecoilRoot>
  </React.StrictMode>
);

reportWebVitals();