import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import MainView from "../views/MainView";

const createRoutes = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<MainView/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default createRoutes;