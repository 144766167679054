import React, { useState } from 'react';
import { Button, Grid, Typography, TextField, MenuItem } from '@mui/material';

function DogFoodCalculator() {
  const [weight, setWeight] = useState('');
  const [ageGroup, setAgeGroup] = useState('puppy_under_4');
  const [foodCalories, setFoodCalories] = useState(null);
  const [treatCalories, setTreatCalories] = useState(null);
  const [caloriesPerKg, setCaloriesPerKg] = useState('');
  const [foodWeight, setFoodWeight] = useState(null);

  const handleWeightChange = (e) => {
    setWeight(e.target.value);
  };

  const handleAgeGroupChange = (e) => {
    setAgeGroup(e.target.value);
  };

  const handleCaloriesPerKgChange = (e) => {
    setCaloriesPerKg(e.target.value);
    if (foodCalories) {
      const caloriesPerKgNum = parseFloat(e.target.value);
      if (!isNaN(caloriesPerKgNum) && caloriesPerKgNum > 0) {
        const foodAmountInGrams = (parseFloat(foodCalories) / caloriesPerKgNum) * 1000;
        setFoodWeight(`${foodAmountInGrams.toFixed(0)} g`);
      }
    }
  };

  const calculateRER = (weight) => {
    return weight * 30 + 70;
  };

  const calculateFoodAmount = () => {
    const weightNum = parseFloat(weight);

    if (isNaN(weightNum) || weightNum <= 0) {
      setFoodCalories('유효한 체중을 입력하세요.');
      return;
    }

    const RER = calculateRER(weightNum);
    let calorieNeeds = 0;

    switch (ageGroup) {
      case 'puppy_under_4':
        calorieNeeds = RER * 3;
        break;
      case 'puppy_4_to_12':
        calorieNeeds = RER * 2;
        break;
      case 'adult_neutered':
        calorieNeeds = RER * 1.6;
        break;
      case 'adult_not_neutered':
        calorieNeeds = RER * 1.8;
        break;
      case 'overweight':
        calorieNeeds = RER * 1.4;
        break;
      case 'obese':
        calorieNeeds = RER * 1;
        break;
      case 'senior_neutered':
        calorieNeeds = RER * 1.2;
        break;
      case 'senior_not_neutered':
        calorieNeeds = RER * 1.4;
        break;
      default:
        setFoodCalories('유효한 상태를 선택하세요.');
        return;
    }

    setFoodCalories(`${calorieNeeds.toFixed(0)} kcal`);
    setTreatCalories(`${(RER * 0.1).toFixed(0)} kcal`);
    setFoodWeight(null); // 초기화: 사료 g 수를 구하기 전까지 비워둠
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '80vh' }} // 화면 정중앙에 배치
      spacing={2}
    >
      <Grid item xs={12} style={{ width: '100%' }} align="center">
        <Typography variant="h5" color="primary">우리 반려견 적정 사료량과 간식량 계산기</Typography>
      </Grid>
      <Grid item xs={12} style={{ width: '100%' }}>
        <TextField
          fullWidth
          label="강아지 체중 (kg)"
          type="number"
          value={weight}
          onChange={handleWeightChange}
          min="0"
          step="any"
          variant="outlined"
          margin="normal"        
        />
      </Grid>
      <Grid item xs={12} style={{ width: '100%' }}>
        <TextField
          fullWidth
          select
          label="강아지 상태"
          value={ageGroup}
          onChange={handleAgeGroupChange}
          variant="outlined"
          margin="normal"
        >
          <MenuItem value="puppy_under_4">생후 4개월 이하</MenuItem>
          <MenuItem value="puppy_4_to_12">생후 4개월 ~ 1년</MenuItem>
          <MenuItem value="adult_neutered">성견 (중성화됨)</MenuItem>
          <MenuItem value="adult_not_neutered">성견 (중성화되지 않음)</MenuItem>
          <MenuItem value="overweight">과체중</MenuItem>
          <MenuItem value="obese">비만</MenuItem>
          <MenuItem value="senior_neutered">7세 이상 (중성화됨)</MenuItem>
          <MenuItem value="senior_not_neutered">7세 이상 (중성화되지 않음)</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} style={{ width: '100%' }}>
        <Button variant="contained" color="primary" onClick={calculateFoodAmount} fullWidth sx={{ color: 'white', fontWeight:'bold' }}>
          권장 Kcal 계산하기
        </Button>
      </Grid>
      {foodCalories && (
        <Grid item xs={12} style={{ width: '100%' }}>
          <Typography variant="h6" color="primary">필요한 사료량: {foodCalories}</Typography>
          <Typography variant="h6" color="primary">권장 간식량: {treatCalories}</Typography>
        </Grid>
      )}
      {foodCalories && (
        <Grid item xs={12} style={{ width: '100%' }}>
          <TextField
            fullWidth
            label="사료의 kcal/kg"
            type="number"
            value={caloriesPerKg}
            onChange={handleCaloriesPerKgChange}
            min="0"
            step="any"
            variant="outlined"
            margin="normal"
          />
          {foodWeight && (
            <Typography variant="h6" color="primary">
              권장되는 사료량: {foodWeight}
            </Typography>
          )}
        </Grid>
      )}
      <Grid item xs={12} style={{ width: '100%' }}>
        <iframe width="100%" height="315" src="https://www.youtube.com/embed/F8k2PlA7VFU?si=-scHKhgz0ZjAnHx4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <Typography variant="caption" color="primary">* 위 동영상을 바탕으로 제작되었습니다.</Typography>
      </Grid>
    </Grid>
  );
}

export default DogFoodCalculator;