import React from "react";

import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import useScrollTrigger from "@mui/material/useScrollTrigger";

const CustomAppBar = styled(AppBar)(({ trigger }) => ({
  backgroundColor: trigger ? "rgba(0,0,0,0.2)" : "transparent",
  padding: "20px 0",
}));

export default function UniversalAppBar() {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
  });

  return (
    <CustomAppBar trigger={trigger} elevation={0}>
      <Container maxWidth="lg">
        <Grid container alignItems="center">
          <Grid item xs={3}>
            <Typography variant="h5" color="primary">
              Kapital Letter
            </Typography>
          </Grid>
          <Grid container item xs={6} justifyContent="space-evenly">
            <Grid item>
              <ButtonBase>
                <Typography variant="h6" color="#ffffff">
                  About
                </Typography>
              </ButtonBase>
            </Grid>
            <Grid item>
              <ButtonBase>
                <Typography variant="h6" color="#ffffff">
                  Service
                </Typography>
              </ButtonBase>
            </Grid>
            <Grid item>
              <ButtonBase>
                <Typography variant="h6" color="#ffffff">
                  Recruite
                </Typography>
              </ButtonBase>
            </Grid>
            <Grid item>
              <ButtonBase>
                <Typography variant="h6" color="#ffffff">
                  Contact
                </Typography>
              </ButtonBase>
            </Grid>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </Container>
    </CustomAppBar>
  );
}
